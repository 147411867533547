import React from "react";

const HomeBanner = () => {
  return (
    <>
      <section
      
        id="hero-4"
        className="hero-section pt-lg-5 p-0"
        style={{ background: "linear-gradient(to bottom, var(--primary-color), var(--body-color))" }}
      >
        <div className="container pt-lg-5 pt-0">
          <div className="row d-flex pt-lg-5 pt-3 align-items-center">
            {/* <!-- HERO-4 IMAGE -->	 */}
            <div className="col-md-5 mb-md-0 mb-3 col-lg-6">
              <div id="hero-4-1" className="hero-4-img">
                {/* <!-- Text -->	 */}
                <h3 className="text-white">We know how to keep your hair looking its best</h3>

                {/* <!-- Image -->	 */}
                <img
                  className="img-fluid"
                  src="images/Headers/hero-4-1.webp"
                  alt=""
                />
              </div>
            </div>

            {/* <!-- HERO-4 IMAGE -->	 */}
            <div className="col-md-7 col-lg-6">
              <div id="hero-4-2" className="hero-4-img">
                <img
                  className="img-fluid"
                  src="images/Headers/hero-4-2.webp"
                  alt="hero-image"
                />
              </div>
            </div>
          </div>
          {/* <!-- End row --> */}
        </div>
        {/* <!-- End container --> */}
      </section>
    </>
  );
};

export default HomeBanner;
